import { AxiosInstance } from '@griegconnect/krakentools-react-kraken-app'
import { AxiosError, AxiosRequestConfig } from 'axios'

export abstract class BaseApi {
  private httpClient: AxiosInstance

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient
  }

  protected async get<T>(path: string, config?: AxiosRequestConfig): Promise<T | AxiosError> {
    try {
      return (await this.httpClient.get(path, config)).data
    } catch (error) {
      return error as AxiosError
    }
  }

  protected async post<T>(path: string, data?: unknown, config?: AxiosRequestConfig): Promise<T | AxiosError> {
    try {
      if (!config) {
        config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      }

      return (await this.httpClient.post(path, data, config)).data
    } catch (error) {
      return error as AxiosError
    }
  }

  protected async put<T>(path: string, data?: unknown, config?: AxiosRequestConfig): Promise<T | AxiosError> {
    try {
      if (!config) {
        config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      }
      return (await this.httpClient.put(path, data, config)).data
    } catch (error) {
      return error as AxiosError
    }
  }

  protected async patch<T>(path: string, data: unknown, config?: AxiosRequestConfig): Promise<T | AxiosError> {
    try {
      return (await this.httpClient.patch(path, data, config)).data
    } catch (error) {
      return error as AxiosError
    }
  }

  protected async delete<T>(path: string, config?: AxiosRequestConfig): Promise<T | AxiosError> {
    try {
      return (await this.httpClient.delete(path, config)).data
    } catch (error) {
      return error as AxiosError
    }
  }
}
