import { BaseApi } from '../BaseApi'
import { DeleteDto, EmbedParamsDto, ExportReportInputDto, ExportReportOutputDto, ExportStatusDto, Operation, PatchReportDto, PowerBiReportDto, ReportDto, ReportPageDto } from './open-api'

export default class ReportsApi extends BaseApi {
  async getReportPages(id: number) {
    return await this.get<ReportPageDto[]>(`reports/${id}/pages`)
  }
  /**
   * @returns All available reports for the current tenant
   */
  async getReports() {
    return await this.get<ReportDto[]>(`reports`)
  }

  /**
   * @returns All available power bi reports in the tenants workspace
   */
  async getPowerBiReports() {
    return await this.get<PowerBiReportDto[]>(`reports/powerbireports`)
  }

  /**
   * @returns All available power bi reports in the tenants workspace
   */
  async postPowerBiReports(ids: string[]) {
    return await this.post<ReportDto[]>(`reports/powerbireports`, ids)
  }

  /**
   * @returns The embed params for the tenants default report
   */
  async getDefaultReportEmbedParams() {
    return await this.get<EmbedParamsDto>(`reports/embed/default`)
  }

  /**
   * @returns Ok if deleted
   */
  async deleteReport(id: number) {
    return await this.delete<DeleteDto>(`reports/${id}`)
  }

  /**
   *
   * @param patchReportDto The updated report dto
   * @returns The updated report
   */
  async patchReport(patchReportDto: PatchReportDto, id: number) {
    const generatedPatchDoc = generatePatchDoc(patchReportDto)
    return await this.patch<ReportDto>(`reports/${id}`, generatedPatchDoc)
  }

  async exportReport(id: number, exportReportDto: ExportReportInputDto) {
    return await this.post<ExportReportOutputDto>(`reports/${id}/exportTo`, exportReportDto)
  }

  async getExportStatus(id: number, exportId: string) {
    return await this.get<ExportStatusDto>(`reports/${id}/exports/${exportId}`)
  }

  async downloadExport(id: number, exportId: string) {
    return await this.get<Blob>(`reports/${id}/exports/${exportId}/download`, {
      responseType: 'blob',
    })
  }

}

function generatePatchDoc(data: Record<string, unknown>): Operation[] {
  const patchedData: Operation[] = Object.keys(data).map((value) => ({
    op: 'replace',
    path: `/${value}`,
    value: data[value],
  }))

  return patchedData
}
