import { CompanyInfoDto } from './open-api'
import { BaseApi } from '../BaseApi'

export default class SettingsApi extends BaseApi {
  /**
   * @returns companyInfo from companyRef/tenant id
   */
  async getCompanyInformationByCompanyRef() {
    return await this.get<CompanyInfoDto>(`settings/companyinfo`)
  }
}
