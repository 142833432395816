export const COMMENT_MAX_LENGTH = 255
export const PATH_TENANTS_BASE = '/companies/:tenantRef'
export const PATH_TENANTS_MATCH_PARAM = 'tenantRef'
export const MOMENT_DATE_FORMAT = 'YYYY-MM-DD'
export const SCHEDULE_COLUMNS_VISIBLE = 7
export const MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:MM'
export const DATE_PICKER_FORMAT = 'yyyy/MM/dd'
export const LUXON_DATE_FORMAT = 'yyyy-LL-dd'
export const LUXON_DATE_TIME_FORMAT = 'yyyy-LL-dd HH:mm'
export const LUXON_TIME_FORMAT = 'HH:mm:ss'
export const NATIVE_TIME_FORMAT = 'HH:mm'

export const ButtonSize = 'medium'

export const amPmCodes = {
  am: 'AM',
  pm: 'PM',
}
