import {
  ApplicationProvider,
  AxiosInstance,
  ConfigType,
  ITenantAccess,
  Tenant,
  TenantContextWrapper,
} from '@griegconnect/krakentools-react-kraken-app'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ReactDOM from 'react-dom'

import './i18n/i18n'
import reportWebVitals from './reportWebVitals'
import { AppNavigation, LocaleWrapper } from './setup'
import { ApiServicesWrapper } from './setup/api/ApiServicesWrapper'
import BaseInfo from './setup/baseinfo/BaseInfo'
import { getOnboarding } from './setup/onboarding/getOnboarding'
import ScrollWrapper from './setup/ScrollWrapper'
import SignalRWrapper from './setup/signalr/SignalRWrapper'
import StyleWrapper from './setup/StyleWrapper'
import TenantWrapper from './setup/tenant/TenantWrapper'
import { ZodWrapper } from './setup/ZodWrapper'
import { PATH_TENANTS_BASE, PATH_TENANTS_MATCH_PARAM } from './shared/constants/constants'

LicenseInfo.setLicenseKey(
  'ec43039a27108c0816e8faad70378351Tz00OTExMCxFPTE2OTIxODE4MDUwODAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
)

/**
 * IF ONE WANTS ONBOARDING INSTEAD OF OPEN FRONT PAGE (ADD THIS PROPERTY TO APPLICATION PROVIDER)
  onboarding={{
    disableCreateAccount: false,
    carouselElements: [
      {
        component: <h1>Slide</h1>,
        title: 'Explore',
        description:
          'Explore',
      },
    ],
  }}
 */

// https://github.com/mui/material-ui/issues/35287#issuecomment-1337250566
// This is needed until we bump versions up to react 18 etc.
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined
      onResizeCapture?: ReactEventHandler<T> | undefined
      nonce?: string | undefined
    }
  }
}

const filterUserAccess = (
  tenants: ITenantAccess[],
  config: ConfigType,
  httpClient: (baseUrl: string, useBearerToken?: boolean | undefined) => AxiosInstance
) => {
  return tenants.reduce<Tenant.Type[]>((acc, cur) => {
    acc.push({
      id: cur.tenant.id,
      name: cur.tenant.name,
      permissions: cur.permissions ?? [],
      ref: cur.tenant.ref,
      companies: cur.companies.map((c) => ({
        ...c.company,
        permissions: c.permissions ?? [],
      })),
    })
    return acc
  }, [])
}

ReactDOM.render(
  <ScrollWrapper>
    <ApplicationProvider
      name='Explore'
      tool='explore'
      appRef='explore'
      applicationSwitcher
      confirmInvitation
      tenantsUrlMatchPathPrefix={PATH_TENANTS_BASE}
      tenantsUrlMatchPathParam={PATH_TENANTS_MATCH_PARAM}
      onboarding={getOnboarding}
      filterUserAccess={{ type: 'http-client', callback: filterUserAccess }}
    >
      <ApiServicesWrapper>
        <TenantContextWrapper selectTenantType='page'>
          <LocaleWrapper>
            <TenantWrapper>
              <ZodWrapper>
                <BaseInfo>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <SignalRWrapper>
                      <AppNavigation />
                    </SignalRWrapper>
                  </LocalizationProvider>
                </BaseInfo>
              </ZodWrapper>
            </TenantWrapper>
          </LocaleWrapper>
        </TenantContextWrapper>
      </ApiServicesWrapper>
    </ApplicationProvider>
  </ScrollWrapper>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
