import { createContext, useContext } from 'react'

import ReportsApi from '../../api/explore/ReportsApi'
import SettingsApi from '../../api/explore/SettingsApi'
import TenantApi from '../../api/explore/TenantApi'

export type ApiServicesContextValues = {
  settingsApi: SettingsApi,
  tenantApi: TenantApi,
  reportsApi: ReportsApi
}

export const ApiServicesContext = createContext<ApiServicesContextValues | null>(null)

export const useApiServices = (): ApiServicesContextValues => {
  const context = useContext(ApiServicesContext)

  if (!context) throw new Error('There is no context values for api')

  return context
}
