import { IUsersWithRoles } from '@griegconnect/krakentools-react-tenants'
import { atom } from 'recoil'

export const tenantUsersAtom = atom<IUsersWithRoles>({
  key: 'services.tenantUsersAtom',
  default: [],
})

export const tenantUsersEmailAtom = atom<string[]>({
  key: 'services.tenantUsersEmailAtom',
  default: [],
})

export const tenantStartOfTheDayAtom = atom<string>({
  key: 'services.tenantStartOfTheDay',
  default: '',
})
