import { ReactComponent as Slide1 } from './slides/dashboards.svg'

export const getOnboarding = {
  disableCreateAccount: false,
  carouselElements: [
    {
      component: <Slide1 />,
      title: 'Explore',
      description: 'Deep-dive into the data from Grieg Connects software suite',
    },
  ],
}
