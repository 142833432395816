import { BaseApi } from '../BaseApi'

export default class TenantApi extends BaseApi {
  async enlistClientReporting(connectionId?: string | null) {
    return await this.put<unknown>(`tenant/enlist/${connectionId}`)
  }

  async delistClientReporting(connectionId?: string | null) {
    return await this.put<unknown>(`tenant/delist/${connectionId}`)
  }
}
