import { createContext, useContext, useMemo, useState } from 'react'

import { Settings } from 'luxon'
import { useTranslation } from 'react-i18next'

import { defaultLocale as i18nDefaultLocale, defaultTimeZone as i18nDefaultTimeZone, supportedLocales } from '../i18n/i18n.config'

/**
 * Context Provider Configuration
 */

export type LocaleType = {
  currentLocale: string
  currentTimeZone: string
  changeLocale: (code: string) => Promise<string>
  changeTimeZone: (timeZone: string | null) => Promise<string>
}

const defaultLocaleContext: LocaleType = {
  currentLocale: i18nDefaultLocale,
  currentTimeZone: i18nDefaultTimeZone,
  changeLocale: () => Promise.resolve(i18nDefaultLocale),
  changeTimeZone: () => Promise.resolve(i18nDefaultTimeZone),
}

const LocaleContext = createContext<LocaleType>(defaultLocaleContext)
export const useLocale = () => useContext<LocaleType>(LocaleContext)

/**
 * FOR INFORMATION REGARDING SUPPORTED FUNCTIONALITY IN LUXON, SEE:
 * https://moment.github.io/luxon/docs/manual/matrix.html
 */

export const LocaleWrapper: React.FC = ({ children }) => {
  const [timeZone, setTimeZone] = useState<string>(i18nDefaultTimeZone)
  const [locale, setLocale] = useState<string>(i18nDefaultLocale)
  const { i18n } = useTranslation()

  const timeZoneChangeHandler = (timeZone: string | null): Promise<string> => {
    return new Promise<string>((resolve) => {
      setTimeZone(timeZone ?? i18nDefaultTimeZone)
      Settings.defaultZone = timeZone ?? i18nDefaultTimeZone
      resolve(timeZone ?? i18nDefaultTimeZone)
    })
  }

  const localeChangeHandler = useMemo(
    () =>
      (localeCode: string | undefined): Promise<string> => {
        return new Promise((resolve, reject) => {
          if (localeCode !== undefined) {
            const suppLocale = supportedLocales.find((sl: string) => sl === localeCode)
            if (suppLocale) {
              i18n
                .changeLanguage(localeCode)
                .then(() => {
                  setLocale(localeCode ?? i18nDefaultLocale)
                  Settings.defaultLocale = localeCode ?? i18nDefaultLocale
                  resolve(localeCode ?? i18nDefaultLocale)
                })
                .catch((e) => {
                  reject(e)
                })
            } else {
              reject('The provided locale is not supported.')
            }
          } else {
            setLocale(i18nDefaultLocale)
            resolve(i18nDefaultLocale)
          }
        })
      },
    [i18n]
  )

  return (
    <LocaleContext.Provider
      value={{
        currentLocale: locale,
        currentTimeZone: timeZone,
        changeLocale: localeChangeHandler,
        changeTimeZone: timeZoneChangeHandler,
      }}
    >
      <>{children}</>
    </LocaleContext.Provider>
  )
}

export default LocaleWrapper
