import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { defaultLocale, supportedLocales } from './i18n.config'

const options = {
  supportedLngs: supportedLocales,
  lng: defaultLocale,
  fallbackLng: (lang) => {
    const fallbacks = []
    const [language, region] = lang.split('-')
    if (supportedLocales.includes(lang) && region) {
      fallbacks.push(language)
    }
    if (language !== 'en') {
      fallbacks.push(defaultLocale)
    }
    return fallbacks
  },
  debug: false,
  nsSeparator: ':',
  keySeparator: '.',
  ns: ['Common', 'zod'],
  defaultNS: 'Common',
  fallbackNS: [],
  cleanCode: true,
  load: 'all',
  react: {
    useSuspense: false,
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
    allowMultiLoading: false,
  },
}

i18n.use(HttpApi).use(initReactI18next).init(options)

export default i18n
