import React, { useEffect, useState } from 'react'

import { Navigation, Tenant, userAtom, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { useRecoilValue } from 'recoil'

import { applicationRoutes } from '../modules/Routes'

export type AccessDependencies = {
  isAuthenticated: boolean
  activeTenant: Tenant.Type | null
  tenantInfo: string | null
  tenants: Tenant.Type[] | null
}

export const AppNavigation: React.FC = () => {
  const [tenantInfo, setTenantInfo] = useState<AccessDependencies['tenantInfo']>(null)
  const { activeTenant, tenants } = useTenants()
  const user = useRecoilValue(userAtom)

  useEffect(() => {
    if (activeTenant) {
      // Perform some async fetch here to set tenant info
      setTenantInfo('Tenant info has been set.')
    } else {
      setTenantInfo(null)
    }
    return () => {
      setTenantInfo(null)
    }
  }, [activeTenant, setTenantInfo])

  return (
    <Navigation
      dependencies={{
        isAuthenticated: !!user,
        activeTenant,
        tenantInfo,
        tenants,
      }}
      navigation={applicationRoutes}
    />
  )
}

export default AppNavigation
