import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import z from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

export const ZodWrapper: FC = ({ children }) => {
  const { t } = useTranslation('zod')

  z.setErrorMap(makeZodI18nMap(t))

  return <>{children}</>
}
