import { alpha, Box, useTheme } from '@mui/material'

const ScrollWrapper = ({ children }) => {
  const theme = useTheme()
  return (
    <Box
      id={'super wrapper'}
      sx={{
        height: '100%',
        overscrollBehavior: 'contain',
        '& ::-webkit-scrollbar': {
          width: '7px',
          height: '7px',
        },
        '& ::-webkit-scrollbar-track': {
          backgroundColor: 'inherit',
        },
        '& ::-webkit-scrollbar-thumb': {
          borderRadius: '4px',
          backgroundClip: 'padding-box',
          backgroundColor: theme.palette.grey[400],
        },
        '& ::-webkit-scrollbar-thumb:hover': {
          borderRadius: '4px',
          backgroundClip: 'padding-box',
          backgroundColor: alpha(theme.palette.grey[400], 0.6),
        },
      }}
    >
      {children}
    </Box>
  )
}

export default ScrollWrapper
