import React from 'react'

import { Settings, Dashboard } from '@griegconnect/krakentools-react-icons'
import { Navigation, requiresPermission, requiresTenant } from '@griegconnect/krakentools-react-kraken-app'
import { Trans } from 'react-i18next'

import { AccessDependencies } from '../../setup/AppNavigation'
import { PATH_TENANTS_BASE } from '../../shared/constants/constants'
import { PermissionDomain, ExplorePermission } from '../../shared/Permissions'

const HomeRoute = React.lazy(() => import('../HomeRoute'))

const DashboardRoutes = React.lazy(() => import('./dashboard/DashboardRoutes'))
const PowerBiReportSettingsRoute = React.lazy(() => import('./settings/powerbi-reports/PowerBiReportSettingsRoute'))
const ReportSettingsRoute = React.lazy(() => import('./settings/reports/ReportSettingsRoute'))

export const routes: Navigation.Item<AccessDependencies>[] = [
  {
    name: 'Home Route',
    displayName: <Trans i18nKey='Menu.Home' />,
    path: `${PATH_TENANTS_BASE}`,
    page: <HomeRoute />,
    exact: true,
    menu: false,
    access: (d) => requiresTenant(d),
  },
  {
    name: 'Dashboard',
    displayName: <Trans i18nKey='Menu.Dashboard' />,
    path: `${PATH_TENANTS_BASE}/dashboard`,
    page: <DashboardRoutes />,
    exact: true,
    menu: {
      icon: <Dashboard />,
    },
    access: (d) =>
      requiresPermission({
        domain: PermissionDomain.Explore,
        name: ExplorePermission.App.Explore,
      })(d),
  },
  {
    name: 'Settings',
    displayName: <Trans i18nKey='Menu.Settings.Settings' />,
    path: `${PATH_TENANTS_BASE}/settings`,
    exact: false,
    menu: {
      icon: <Settings />,
    },
    access: (d) =>
      requiresPermission({
        domain: PermissionDomain.Explore,
        name: ExplorePermission.App.Explore,
      })(d),
    subNavs: [
      {
        name: 'PowerBiReports',
        displayName: <Trans i18nKey='Menu.Settings.PowerBiReports' />,
        path: `${PATH_TENANTS_BASE}/settings/powerbi-reports`,
        page: <PowerBiReportSettingsRoute />,
        exact: true,
        menu: true,
        access: (d) =>
          requiresPermission({
            domain: PermissionDomain.Explore,
            name: ExplorePermission.App.Explore,
          })(d),
      },
      {
        name: 'Reports',
        displayName: <Trans i18nKey='Menu.Settings.Reports' />,
        path: `${PATH_TENANTS_BASE}/settings/reports`,
        page: <ReportSettingsRoute />,
        exact: true,
        menu: true,
        access: (d) =>
          requiresPermission({
            domain: PermissionDomain.Explore,
            name: ExplorePermission.App.Explore,
          })(d),
      }
    ],
  },
  // {
  //   name: 'Test',
  //   path: `${PATH_TENANTS_BASE}/test`,
  //   page: <TestRoute />,
  //   exact: true,
  //   menu: {
  //     icon: <TimeCalendar />,
  //   },
  //   access: (d) =>
  //     requiresPermission({
  //       domain: PermissionDomain.Plan,
  //       name: PlanPermission.App.Plan,
  //     })(d),
  // },
]
