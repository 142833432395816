import { HubConnection } from '@microsoft/signalr'

export type ConnectionState = {
  error?: Error
  loading: boolean
  isConnected: boolean
  accessToken?: string
  connection?: HubConnection
}

export const initialConnectionState = {
  error: undefined,
  loading: true,
  isConnected: false,
  accessToken: undefined,
  connection: undefined,
}
