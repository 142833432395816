import { useCallback } from 'react'

import { useAlerts, useTenantApi, useTenants } from '@griegconnect/krakentools-react-kraken-app'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import { tenantUsersAtom, tenantUsersEmailAtom } from './TenantServicesAtom'
import { useApiServices } from '../../../setup/api/ApiServicesContext'
import { useVerifyResponse } from '../../../setup/api/utils/useVerifyResponse'

export const useTenantServices = () => {
  const { t } = useTranslation()
  const { activeTenant } = useTenants()
  const { enqueue } = useAlerts()
  const { tenantApi } = useApiServices()
  const krakenTenantApi = useTenantApi(activeTenant?.id ?? '0')
  const { verifyResponse } = useVerifyResponse()

  const [tenantUsers, setTenantUsers] = useRecoilState(tenantUsersAtom)
  const [tenantUserEmail, setTenantUserEmail] = useRecoilState(tenantUsersEmailAtom)
  const getTenantUsers = async () => {
    if (tenantUsers.length > 0) return tenantUsers

    const result = await krakenTenantApi.users()
    if (verifyResponse(result, t('ErrorMessages.GetFailed.Users'))) {
      return []
    }
    setTenantUsers(result)
    return result
  }
  const getTenantUserEmails = async () => {
    if (tenantUserEmail.length > 0) return tenantUserEmail

    const result = await getTenantUsers()
    const tenantEmails = result.map((user) => user.email)
    setTenantUserEmail(tenantEmails)
    return tenantEmails
  }

  const getTenantUserNameByEmail = (email: string) => {
    const user = tenantUsers.find((user) => user.email === email)
    return user?.name
  }

  const getTenantUserAuthIdByEmail = (email: string) => {
    const user = tenantUsers.find((user) => user.email === email)
    return user?.id
  }

  const enlistClient = useCallback(
    async (connectionId?: string | null) => {
      if (!connectionId) return null
      const result = await tenantApi.enlistClientReporting(connectionId)
      if (verifyResponse(result, t('ErrorMessages.UpdateFailed.ClientReported'))) {
        return
      }
      return result
    },
    [enqueue, tenantApi, t]
  )

  const delistClient = useCallback(
    async (connectionId?: string | null) => {
      if (!connectionId) return null

      const result = await tenantApi.delistClientReporting(connectionId)
      if (verifyResponse(result, t('ErrorMessages.UpdateFailed.ClientReported'))) {
        return
      }
      return result
    },
    [enqueue, tenantApi, t]
  )

  return {
    getTenantUsers,
    getTenantUserEmails,
    getTenantUserNameByEmail,
    getTenantUserAuthIdByEmail,
    enlistClient,
    delistClient,
  }
}
