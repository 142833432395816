import { createContext, useContext } from 'react'

import { ConnectionState, initialConnectionState } from './state'

export const SignalRContext = createContext<ConnectionState>(initialConnectionState)

const useSignalRContext = () => {
  const context = useContext(SignalRContext)

  if (!context) throw new Error('There is no context values for signalr')

  return context
}

export default useSignalRContext
