export enum PermissionDomain {
  Explore = 'explore', // TODO change to explore or similar
}

export const ExplorePermission = {
  App: {
    Explore: 'app:explore', // TODO change to explore or similar
  },
  Preset: {
    Read: 'preset:read',
    SharedWrite: 'preset:shared:write',
    PrivateWrite: 'preset:private:write',
  },
}
