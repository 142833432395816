import React from 'react'

import { SignalRContext } from './SignalRContext'
import { useConnection } from './useConnection'

const SignalRWrapper = ({ children }) => {
  const connection = useConnection()

  return <SignalRContext.Provider value={connection}>{children}</SignalRContext.Provider>
}

export default SignalRWrapper
