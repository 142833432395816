import { useCallback, useMemo } from 'react'

import { AxiosError, useAlerts } from '@griegconnect/krakentools-react-kraken-app'
import axios from 'axios'

import isAxiosError from './isAxiosError'

type UseVerifyResponseReturnType = {
  /**
   * Verify if the response is not an exception, and if it is, show the message of the exception
   *
   * @param {unknown} response - The response to verify
   * @param {(string | undefined)} message - Override the message to show, if you want to include the message of the exception format it by including {{message}}
   * @returns {boolean} True if the response is an axios error, false otherwise
   * @example verifyResponse(response, 'Error while fetching data: {{message}}')
   */
  verifyResponse: (response: unknown, message?: string) => response is AxiosError
}

export const useVerifyResponse = (): UseVerifyResponseReturnType => {
  const { enqueue } = useAlerts()

  const verifyResponse = useCallback(
    (response: unknown, message?: string): response is AxiosError => {
      if (isAxiosError(response)) {
        if (!axios.isCancel(response))
          enqueue(message ? message.replace('{{message}}', response.message) : response.message, 'error')
        return true
      }
      return false
    },
    [enqueue]
  )

  return useMemo((): UseVerifyResponseReturnType => ({ verifyResponse }), [verifyResponse])
}
