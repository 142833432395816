/**
 * Must be IANA-specified zones that is available in the Intl API
 */
export const defaultTimeZone = 'utc'

/**
 * Must be a supported BCP-47 Language code that is available in the Intl API
 */
export const defaultLocale = 'en'

export const defaultCountryCode = 'gb'

export const supportedLocales: string[] = ['en', 'en-gb', 'en-us', 'no', 'nb-no']
