import { useEffect } from 'react'

import { useTenants } from '@griegconnect/krakentools-react-kraken-app'

// import { useSettingsServices } from '../../api-services/explore/SettingsServices/SettingsServices'

export const BaseInfo: React.FC = ({ children }) => {
// Example left
  //const { getPresets } = useSettingsServices()

  const { activeTenant } = useTenants()

  useEffect(() => {
    if (activeTenant) {
//Example left
      //getPresets()
    }
    // eslint-disable-next-line
  }, [activeTenant])

  return <> {children} </>
}

export default BaseInfo
