import { useEffect, useReducer, useRef } from 'react'

import { useConfig } from '@griegconnect/krakentools-react-kraken-app'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'

import { useTenantServices } from '../../api-services/explore/TenantServices/TenantServices'
import { ConnectionState, initialConnectionState } from './state'
import { log } from './utils'

const startSignalRConnection = async (connection) => {
  try {
    await connection.start()
    log('SignalR connection established')
  } catch (err) {
    log('SignalR Connection Error: ', err)
    setTimeout(() => startSignalRConnection(connection), 5000)
  }
}

export const useConnection = (): ConnectionState => {
  const config = useConfig()
  const { enlistClient, delistClient } = useTenantServices()

  const reducer = (state: ConnectionState, newState: ConnectionState): ConnectionState => ({ ...state, ...newState })
  const [state, setState] = useReducer(reducer, initialConnectionState)

  const componentMounted = useRef(true)

  useEffect(() => {
    return () => {
      componentMounted.current = false
    }
  }, [])

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`${config.api.exploreApiUrl}/explorehub`)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build()

    startSignalRConnection(connection).then(() => {
      if (componentMounted.current) setState({ loading: false, isConnected: true, connection })
      enlistClient(connection.connectionId)
    })

    connection.onclose(() => {
      log('SignalR connection closed')
      delistClient(connection.connectionId)
    })

    connection.onreconnected(() => {
      log('SignalR connection reconnecting')
      enlistClient(connection.connectionId)
    })

    return () => {
      connection.stop()
    }
  }, [config.api.exploreApiUrl, delistClient, enlistClient])

  return state
}
