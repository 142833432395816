import { useMemo } from 'react'

import { activeTenantSelector, useConfig, useHttp } from '@griegconnect/krakentools-react-kraken-app'
import { useRecoilValue } from 'recoil'

import { ApiServicesContext, ApiServicesContextValues } from './ApiServicesContext'
import ReportsApi from '../../api/explore/ReportsApi'
import SettingsApi from '../../api/explore/SettingsApi'
import TenantApi from '../../api/explore/TenantApi'

export const ApiServicesWrapper: React.FC = ({ children }) => {
  const { httpClient } = useHttp()
  const config = useConfig()
  const tenant = useRecoilValue(activeTenantSelector)

  const exploreApiClient = useMemo(
    () => httpClient(`${config.api.exploreApiUrl}/companies/${tenant?.ref}`, true),
    [config.api.exploreApiUrl, httpClient, tenant?.ref]
  )

  const apiServices: ApiServicesContextValues = useMemo(() => {
    return {
      settingsApi: new SettingsApi(exploreApiClient),
      tenantApi: new TenantApi(exploreApiClient),
      reportsApi: new ReportsApi(exploreApiClient)
    }
  }, [exploreApiClient])

  return <ApiServicesContext.Provider value={apiServices}>{children}</ApiServicesContext.Provider>
}
export default ApiServicesWrapper
