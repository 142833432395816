import React from 'react'

import { CompanyInfoDto } from '../../api/explore/open-api/models/CompanyInfoDto'
import { defaultTimeZone, defaultLocale, defaultCountryCode } from '../../i18n/i18n.config'

export const defaultTenantContext: CompanyInfoDto = {
  countryCode: defaultCountryCode,
  locationCode: '',
  currencyCode: '',
  timeZone: defaultTimeZone,
  primaryLanguage: defaultLocale,
  secondaryLanguages: [],
}

const TenantContext = React.createContext<CompanyInfoDto>(defaultTenantContext)
export const useTenant = (): CompanyInfoDto => React.useContext<CompanyInfoDto>(TenantContext)

export default TenantContext
