import React, { useEffect, useRef, useState } from 'react'

import { useTenants, SuspenseLoader } from '@griegconnect/krakentools-react-kraken-app'
import i18next from 'i18next'

import TenantContext, { defaultTenantContext } from './TenantContext'
import { CompanyInfoDto } from '../../api/explore/open-api/models/CompanyInfoDto'
import isAxiosError from '../api/utils/isAxiosError'
import { useApiServices } from '../api/ApiServicesContext'
import { useLocale } from '../LocaleWrapper'

export const TenantWrapper: React.FC = ({ children }) => {
  const [companyInfo, setCompanyInfo] = useState<CompanyInfoDto | null>(null)
  const { activeTenant } = useTenants()
  const { currentLocale, changeTimeZone, changeLocale } = useLocale()
  const { settingsApi } = useApiServices()

  const isMounted = useRef<boolean>(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  })

  useEffect(() => {
    if (activeTenant) {
      const fetchCompanyConfig = async () => {
        const companyConfig = await settingsApi.getCompanyInformationByCompanyRef()
        if (isAxiosError(companyConfig)) {
          setCompanyInfo(defaultTenantContext)
          changeTimeZone(defaultTenantContext.timeZone)
        } else {
          if (isMounted.current) {
            i18next.addResourceBundle(
              currentLocale,
              'Entity',
              {
                // TODO make translations etc work, just english for now.
                // ...staticBackendTranslations,
                // ...dynamicBackendTranslations,
              },
              true,
              true
            )
            companyConfig.countryCode ||= defaultTenantContext.countryCode
            companyConfig.locationCode ||= defaultTenantContext.locationCode
            companyConfig.currencyCode ||= defaultTenantContext.currencyCode
            companyConfig.timeZone ||= defaultTenantContext.timeZone
            companyConfig.primaryLanguage ||= defaultTenantContext.primaryLanguage

            // TODO make translations etc work, just english for now.
            //  changeLocale(companyConfig.primaryLanguage)

            setCompanyInfo(companyConfig)
            changeTimeZone(companyConfig.timeZone)
          }
        }
      }

      fetchCompanyConfig()
    } else {
      if (isMounted.current) {
        setCompanyInfo(null)
        changeTimeZone(null)
      }
    }

    return () => {
      if (isMounted.current) {
        setCompanyInfo(null)
        changeTimeZone(null)
      }
    }
  }, [currentLocale, activeTenant, changeTimeZone, settingsApi])

  // TODO make translations etc work, just english for now.
  //   useEffect(() => {
  //     if (isMounted.current) {
  //       i18next.addResourceBundle(
  //         currentLocale,
  //         'Entity',
  //         {
  //           // ...staticBackendTranslations,
  //           // ...dynamicBackendTranslations,
  //         },
  //         true,
  //         true
  //       )
  //     }
  //   }, [currentLocale])

  //   useEffect(() => {
  //     if (isMounted.current && companyInfo) {
  //       changeLocale(companyInfo.primaryLanguage)
  //     }
  //   }, [changeLocale, companyInfo])

  return (activeTenant && companyInfo) || !activeTenant ? (
    <TenantContext.Provider
      value={{
        ...(companyInfo ? companyInfo : defaultTenantContext),
      }}
    >
      {children}
    </TenantContext.Provider>
  ) : (
    <SuspenseLoader />
  )
}

export default TenantWrapper
